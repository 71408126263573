// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

body {
  background-color: $background-color;
  overflow-x: hidden;
  height: 100%;
}

#widget-launcher-button {
  bottom: 105px !important;
}

@mixin app-page-container {
  display: grid;
  grid-template-columns: 1fr;
  height: 100vh;
  grid-column-gap: 20px;
  grid-template-rows: min-content auto min-content;
  justify-items: stretch;
  align-items: stretch;

  @include media('<=small') {
    grid-column-gap: 6px;
  }

  &.hidden-footer {
    grid-template-rows: min-content auto;
  }
}

.loggedout-app-page-container {
  @include app-page-container;

  &.hidden-header {
    grid-template-rows: auto min-content;

    &.hidden-footer {
      grid-template-rows: auto;
    }

    .footer {
      align-self: end;
    }
  }
}

.loggedin-app-page-container {
  @include app-page-container;

  .loggedin-app-page-wrapper {
    @include media('<=small') {
      margin: 25px 0 0;
    }
  }

  &.hidden-header {
    .loggedin-app-page-wrapper {
      @include media('<=small') {
        margin: 0;
      }
    }

    .footer {
      align-self: end;
    }
  }

  &.hidden-footer {
    .loggedin-app-page-wrapper {
      height: 100vh;
      @include media('<=large') {
        height: 100%;
      }
    }
  }
}

.content-wrapper-animate {
  @include animation('page-fade-in 0.5s ease-in');
}

.layout-2col {
  display: flex;
  align-items: flex-start;
  max-width: 875px;
  margin: auto;

  @include media('<=medium') {
    flex-direction: column;
    align-items: center;
  }

  &__main {
    flex: calc(65% - #{$container-padding/2});
    margin-right: $container-padding/2;

    @include media('<=medium') {
      flex: auto;
      width: 100%;
      margin-right: 0;
      margin-bottom: $container-padding/2;
    }
  }

  &__sidebar {
    flex: 35%;
    max-width: 370px;

    @include media('<=medium') {
      max-width: 100%;
      flex: 100%;
      width: 100%;
      order: 2;
    }
  }

  &__box {
    background-color: #fff;
    padding: $container-inside-padding;
    border: 1px solid $gainsboro;
    border-radius: 20px;

    &:not(:last-child) {
      margin-bottom: $container-padding/2;
    }

    @include media('<=small') {
      padding: $container-inside-padding $mobile-container-padding;
      text-align: center;
    }
  }
}

.box-title {
  font-size: calc-rem(22);
  margin: 0;
  position: relative;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  color: $dodger-blue;
  line-height: 1;

  @include media('<=small') {
    justify-content: center;
    font-size: calc-rem(28);
    font-weight: 500;
  }
}

.box-divider {
  border-bottom: 1px solid $border-color;
  width: calc(100% + #{$container-inside-padding * 2});
  margin: $container-inside-padding 0 $container-inside-padding #{$container-inside-padding * -1};
}

.menu-list {
  list-style-type: none;
  color: #8493a8;
  display: inline-block;
  margin: 0;

  li {
    padding: 0 10px;
    text-align: center;
    display: inline-block;

    a {
      position: relative;
      padding: 5px auto;
      color: #8493a8;
      text-decoration: none;

      @include on-event {
        outline: 0;
        color: $tertiary-text-colour;
      }

      &.active:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -15px;
        border-bottom: 2px solid $brand-pink-colour;
        width: 100%;
      }
    }
  }
}

.sticky-wrapper__item {
  position: fixed;
  top: 142px;
  background: white;
  z-index: 9;
  left: 0;
  right: 0;
  padding: 15px;
  max-width: 1180px;
  margin: auto;
}

.upsell-modal {
  text-align: center;
  border-radius: 5px;
  padding: $container-inside-padding;
  margin: auto;
  width: 100%;
  max-width: 90%;
  min-height: 100%;

  @include media('<=medium') {
    max-width: 100%;
    padding: 0;
  }

  &__close {
    top: 9px;
    right: 15px;
    outline: none;
    cursor: pointer;
  }

  &__container {
    // position: relative;
    // overflow: hidden;
    // padding-bottom: 56.5%;
    // height: 0;
    width: 100%;
    height: 100%;

    iframe {
      position: absolute;
      top: 5px;
      left: 5px;
      width: calc(100% - 10px); //Allow border radius
      height: calc(100% - 10px);
    }
  }
}

.custom-modal {
  padding: $modal-padding;
}
